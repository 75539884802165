//  this might be best used as an npm package to be shared between cloud functions and client apps.
type CloudFunctionNames = {
  addressValidation: string;
  offersDataAggregationCallable: string;
  deptDashboard: string;
  usersByType: string;
  newUsersByDay: string;
  newOpeningsByDay: string;
  outreachList: string;
  collectionCounts: string;
  sliLogger: string;
  resetPassword: string;
  updateResetPassword: string;
  fetchEarnedBadges: string;
  positionMetrics: string;
  getOpenJobMetrics: string;
  getPositionMetrics: string;
  getTop10CoursesByCompletion: string;
  getApplicationTrendsByDay: string;
  getApplicationsPerPosition: string;
  getApplicationTrendsByDayByJobId: string;
  getNewRegistrationMetrics: string;
  getAssessmentMetricsByStatus: string;
  cleanupUserRole: string;
  cleanupOrphanedAuth: string;
  commCreateUser: string;
  commCheckUser: string;
  csvUsers: string;
  deleteApplicationRecursively: string;
  activateUser: string;
  externalJobsStats: string;
  carrerSeekersDashboard:string;
  getSheerIdDataByDay:string;
  employersDashboard:string;
  jobsDashboard:string;
  externalJobsPieChart:string;
  userPieChart:string;
  getInteractionsDataByDay: string;
  sheerIdVerificationDashboardInfo: string;
  csvDataForSuccessfulVerification: string;
  getActiveUsersCSVData: string;
  csvDataForFailedVerification: string;
  csvDataForFailedConversion: string;
  getInteractionsCSVData: string;
  getNewUsersData: string;
  getMAUData: string;
};

const cloudFunctionNames: CloudFunctionNames = {
  addressValidation: "addressValidation-addressValidation",
  offersDataAggregationCallable: "offersDataAggregationCallable",
  deptDashboard: "deptDashboard",
  usersByType: "usersByType",
  newOpeningsByDay: "newOpeningsByDay",
  newUsersByDay: "newUsersByDay",
  outreachList: "outreachList",
  collectionCounts: "collectionCounts",
  sliLogger: "sliLogger",
  resetPassword: "resetPassword",
  updateResetPassword: "updateResetPassword",
  fetchEarnedBadges: "fetchEarnedBadges",
  positionMetrics: "positionMetrics",
  getOpenJobMetrics: "getOpenJobMetrics",
  getPositionMetrics: "getPositionMetrics",
  getApplicationTrendsByDay: "getApplicationTrendsByDay",
  getApplicationsPerPosition: "getApplicationsPerPosition",
  getApplicationTrendsByDayByJobId: "getApplicationTrendsByDayByJobId",
  getTop10CoursesByCompletion: "getTop10CoursesByCompletion",
  getNewRegistrationMetrics: "getNewRegistrationMetrics",
  getAssessmentMetricsByStatus: "getAssessmentMetricsByStatus",
  cleanupUserRole: "cleanupUserRole",
  cleanupOrphanedAuth: "cleanupOrphanedAuth",
  commCreateUser: "commCreateUser",
  commCheckUser: "commCheckUser",
  csvUsers: "csvUsers",
  deleteApplicationRecursively: "deleteApplicationRecursively",
  activateUser: "activateUser",
  externalJobsStats: "externalJobsStats",
  carrerSeekersDashboard: "carrerSeekersDashboard",
  getSheerIdDataByDay: "getSheerIdDataByDay",
  employersDashboard: "employersDashboard",
  jobsDashboard: "jobsDashboard",
  externalJobsPieChart: "externalJobsPieChart",
  userPieChart: "userPieChart",
  getInteractionsDataByDay: "getInteractionsDataByDay",
  sheerIdVerificationDashboardInfo: "sheerIdVerificationDashboardInfo",
  csvDataForSuccessfulVerification: "csvDataForSuccessfulVerification",
  getActiveUsersCSVData: "getActiveUsersCSVData",
  csvDataForFailedVerification: "csvDataForFailedVerification",
  csvDataForFailedConversion: "csvDataForFailedConversion",
  getInteractionsCSVData: "getInteractionsCSVData",
  getNewUsersData: "getNewUsersData",
  getMAUData:"getMAUData"
  
}

export default cloudFunctionNames;
