import React, { useEffect, useState } from "react";
import ApexCharts from "react-apexcharts";
import InfoIcon from "../components/dashboard/AdminMetricDashboard/InfoTextWithIcon";
import LoadingSpinner from "../components/dashboard/AdminMetricDashboard/LoadingIndicator/LoadingSpinner";
import { getInteractionsDataByDay } from "../callable-cloud-functions/cloudFunctions";


function NewUserChart({ daysSelected }) {
  const [chartData, setChartData] = useState([]);
  const [chartKeys, setChartKeys] = useState([]);
  const [chartTitle, setChartTitle] = useState("Last 14 Days");
  const [graphData, setGraphData] = useState()

  useEffect(() => {
    async function fetchData() {
      const { data } = await getInteractionsDataByDay(30)
      setGraphData(data);
    }
    fetchData();
  }, [])

  useEffect(() => {
    const filteredData = graphData?.slice(-daysSelected);
    const bpoData = filteredData?.map(item => item.bpo_count);
    const directHireData = filteredData?.map(item => item.dh_count);
    const totalcountData = filteredData?.map(item => item.total);
    const chartKeys = filteredData?.map(item => item.date.split("-").slice(-2).join("/"));

    setChartData({
      bpo: bpoData,
      directHire: directHireData,
      totalcount: totalcountData
    });
    setChartKeys(chartKeys);
    setChartTitle(`Last ${daysSelected} Days`);
  }, [daysSelected, graphData]);

  const options = {
    chart: {
      type: 'line',
      height: '100%',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false
      }
    },
    xaxis: {
      categories: chartKeys,
      reversed: true, // Reverse the x-axis labels
      labels: {
        rotate: -45, 
        rotateAlways: daysSelected ==='30' ? true:false, // Ensure rotation always applies
        style: {
          fontSize: '12px',
          colors: '#333', // Default color for labels
        },
      },
    },
    plotOptions: {
      bar: {
        columnWidth: '50%',
        states: {
          hover: {
            filter: {
              type: 'none', // Disable the lighten effect
            },
            color: '#000000', // Light gray color on hover
          },
        },
      },
    },
    yaxis: {
      title: {
        text: 'Total Interactions',  // Left side axis
      },
      min: 0,
      opposite: false,  // Left side only
    },
    stroke: {
      curve: 'straight',
      width: 2,
    },
    markers: {
      size: 5,
      strokeWidth: 0,
      hover: {
        size: 7,
      },
    },
    colors: ['#CAE5FD', '#205A6E', '#183853', '#D3D3D3'], // Colors for lines and bars
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        columnWidth: '50%',
      },
    },
    series: [

      {
        name: "BPO Jobs",
        type: 'bar',
        data: chartData.bpo,
        color: '#205A6E',
      },
      {
        name: "Direct Hire",
        type: 'bar',
        data: chartData.directHire,
        color: '#183853',
      },
      {
        name: "Total Interactions",
        type: 'bar',
        data: chartData.totalcount,
        color: '#bcbcbc',
      },
    ],
  };

  return (
    <div className="bg-white shadow-lg rounded-lg p-4 pb-8">
      <div className="w-full text-[#c4e6f3] pb-3  h-80">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <h1 className='text-xl text-[#1E596D] font-bold'>
              {`Interactions`}
            </h1>
            <p className="text-10px ml-[3px] text-[#3b7f96]">
              {`(Last ${daysSelected} Days)`}
            </p>
            <InfoIcon tooltipText={"Number of unique clicks on the ‘View Posting’ button on the job application page. Clicks on different platforms count as new interactions (Android/ iOS/ Web)."} />
          </div>


        </div>
        {graphData?.length === 0 ? (
          <div className="flex justify-center items-center h-full">
            <LoadingSpinner />
          </div>
        ) : (
          <ApexCharts options={options} series={options.series} type="line" height="100%" />
        )}
      </div>
    </div>
  );
}

export default NewUserChart;
